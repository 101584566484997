.table{
    border: 1px solid;
    padding: 16px;
    width: 90%;
    height:250px;
    margin: 0 auto;
    margin-top: 2%;
    border-collapse: collapse;
    table-layout: fixed ;
    border-radius: 12px !important;
    
}
.table th ,.table td{
  width: 200px;
    border: 1px solid;
}
.main {
    margin: 50px;
    overflow: hidden;
    position: relative;
    width: 350px;
  }
 
  .text {
    background-color: white;
    padding: 30px;
    position: relative;
  }
  .text > div {
    position: relative;
    z-index: 1;
  }
  .text:before {
    content: "";
    background-color: white;
    position: absolute;
    height: 100%;
    width: 120%;
    top: -20px;
    left: -10%;
    transform: rotate(5deg);
    z-index: 0;
  }
